export default function HomeMain() {
  return (
    <div className=" text-2xl text-center">
      <div>
        <h2>Hi, I'm David</h2>
        <p>Welcome to my site!</p>
        <p>take a look around.</p>
      </div>
    </div>
  );
}
